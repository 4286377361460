import { useContext, useEffect, useState } from 'react'

// MUI imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { Theme } from '@mui/material/styles/createTheme'

// CompendiumX components
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { CXIconArticle } from 'components/CXIcon/CXIcon'

// Context
import { ErrorContext } from 'context/ErrorContext'

// Functional
import { getChangedNodes, getTotalChangedNodes, truncateToc } from './TocPanel.helpers'
import { getDocumentToc } from './TocPanel.service'

// Types
import { DocumentTocProps } from './TocPanel.type'

interface Props {
  documentId: string
  articleId: string
  anchor?: string
  setAnchor: (anchor: string) => void
  tocPanelActive: boolean
  handleSetTocPanelActive: (bool: boolean) => void
  handleEnforceRevisionsTabActive: (bool: boolean) => void
}

const TocPanel: React.FC<Props> = (props) => {
  const { documentId, articleId, setAnchor, tocPanelActive, handleSetTocPanelActive, handleEnforceRevisionsTabActive } = props
  const [loading, setLoading] = useState(false)
  // Error handling
  const { setErrorMessage } = useContext(ErrorContext)

  // Toc stuff
  const [openToc, setOpenToc] = useState<string[]>([])
  const [reviewsActive, setReviewsActive] = useState(false)
  const [documentToc, setDocumentToc] = useState<DocumentTocProps[]>()
  const [openRevisions, setOpenRevisions] = useState<string[]>([])

  // Get Document
  useEffect(() => {
    const fetchToc = async (): Promise<void> => {
      setLoading(true)
      try {
        setDocumentToc(await getDocumentToc(documentId))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    fetchToc()
    // eslint-disable-next-line
  }, [documentId])

  const [changedNodes, setChangedNodes] = useState<{ nodes?: DocumentTocProps[] }>()
  const [totalChanged, setTotalChanged] = useState<number>(0)
  // Get Changes for TOC
  useEffect(() => {
    setChangedNodes(getChangedNodes(documentToc))
    setTotalChanged(getTotalChangedNodes(documentToc))
    // eslint-disable-next-line
  }, [documentToc])

  return (
    <Box sx={{ position: 'absolute', width: '100%', height: '90%', display: 'flex', pr: 2 }}>
      {loading && !tocPanelActive && (
        <Box
          sx={({ palette: { white } }: Theme) => ({
            backgroundColor: white.main,
            height: '100vh',
            width: '150px',
          })}
        >
          <Stack spacing={3} sx={{ alignItems: 'center', mt: '150px', mr: 3 }} direction={'column'}>
            <CircularProgress size={50} />
          </Stack>
        </Box>
      )}
      {loading && tocPanelActive && (
        <Box
          sx={({ palette: { white } }: Theme) => ({
            backgroundColor: white.main,
            height: '100vh',
            width: '100%',
          })}
        >
          <Stack spacing={3} sx={{ alignItems: 'center', mt: '150px' }} direction={'column'}>
            <CircularProgress size={50} />
            <MDTypography>Loading table of content ...</MDTypography>
          </Stack>
        </Box>
      )}
      {!loading && (
        <>
          <Box
            py={2}
            px={5}
            sx={({ palette: { white, grey } }: Theme) => ({
              backgroundColor: white.main,
              borderRight: !tocPanelActive ? `20px solid ${grey[400]}` : 0,
            })}
          >
            <Stack direction="column" spacing={3}>
              <MDButton color="dark" variant="outlined" sx={{ px: '10px', width: '40px', minWidth: 0 }} onClick={() => handleSetTocPanelActive(!tocPanelActive)}>
                <Icon>{tocPanelActive ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}</Icon>
              </MDButton>
              {/* <Box sx={{ backgroundColor: 'red', borderRadius: '8px 0px 0px 8px' }}>
                  <IconButton size="small">
                    <CXIconArticle />
                  </IconButton>
                </Box> */}
              <Box
                sx={{
                  backgroundColor: 'primary.additional',
                  color: 'primary.focus',
                  borderRadius: tocPanelActive ? '8px 0px 0px 8px' : '50%',
                  width: tocPanelActive ? '55px' : '35px',
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  transition: 'all 150ms ease',
                  marginRight: tocPanelActive ? '-18px !important' : 0,
                  position: 'relative',
                  '&:hover': {
                    boxShadow: '0 0 0 5px #F2F2FF',
                  },
                }}
                onClick={() => handleSetTocPanelActive(!tocPanelActive)}
              >
                <IconButton size="small" color="inherit">
                  <CXIconArticle />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          {tocPanelActive && (
            <Box
              py={6}
              px={3}
              sx={{
                backgroundColor: 'grey.50',
                width: '80%',
                height: '100vh',
                boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
                overflow: 'scroll',
              }}
            >
              <Stack direction={'column'}>
                <MDTypography
                  variant="textXL_SB"
                  sx={({ palette: { grey } }: Theme) => ({
                    color: grey[900],
                  })}
                >
                  Table of contents
                </MDTypography>
                <MDTypography
                  variant="textSM"
                  sx={({ palette: { grey } }: Theme) => ({
                    color: grey[700],
                  })}
                >
                  Summary of the document
                </MDTypography>
                <Box my={2} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <MDTypography
                    variant="textSM_M"
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                      mr: 1,
                    })}
                  >
                    See revisions
                  </MDTypography>
                  <Switch checked={reviewsActive} onChange={() => setReviewsActive(!reviewsActive)} />
                </Box>
                <MDTypography
                  variant="textSM_M"
                  sx={{
                    color: '#6060FF',
                    display: 'block',
                  }}
                >
                  ({totalChanged} changes)
                </MDTypography>
                {/* PAYWALL */}
                {/* <Box
                    p={'8px'}
                    sx={({ palette: { grey } }: Theme) => ({
                      backgroundColor: grey[100],
                      borderRadius: '4px',
                      border: `1px solid ${grey[300]}`,
                      position: 'relative',
                    })}
                  >
                    <MDTypography
                      variant="textXS_M"
                      sx={({ palette: { grey } }: Theme) => ({
                        color: grey[900],
                      })}
                    >
                      Upgrade to see revision
                    </MDTypography>
                    <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                      <MDTypography
                        variant="textSM_M"
                        sx={({ palette: { grey } }: Theme) => ({
                          color: grey[500],
                          mr: 1,
                        })}
                      >
                        See revisions
                      </MDTypography>
                      <Switch checked={false} disabled />
                    </Box>
                    <MDTypography
                      variant="textSM_M"
                      sx={{
                        color: '#6060FF',
                        display: 'inline-block',
                      }}
                    >
                      (2 changes)
                    </MDTypography>
                    <MDButton type="submit" variant="gradient" color="primary" size="small" startIcon={<CXIconUpgrade />} sx={{ float: 'right' }}>
                      Upgrade
                    </MDButton>
                  </Box> */}
                {/* END PAYWALL */}
                <Box>
                  <Divider color="primary" />
                </Box>
                {reviewsActive &&
                  changedNodes?.nodes &&
                  changedNodes.nodes
                    ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((changedNode) => (
                      <Box>
                        <>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              onClick={() => {
                                setAnchor(changedNode.anchor_id)
                                handleEnforceRevisionsTabActive(true)
                              }}
                              sx={{
                                backgroundColor: changedNode.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                transition: 'all 0.5s',
                                '&:hover': {
                                  cursor: 'pointer',
                                  backgroundColor: '#FEF0C7',
                                },
                              }}
                            >
                              <MDTypography variant="textSM_SB">{changedNode.nr}:</MDTypography>
                              <MDTypography variant="textSM_M" sx={{ fontWeight: 400, color: '#6c757d', ml: 1 }}>
                                {changedNode.title && truncateToc(changedNode.title)}
                              </MDTypography>
                            </Box>
                          </Box>
                          {changedNode?.x_child_node && openRevisions.indexOf(changedNode.anchor_id) < 0 && (
                            <Icon fontSize="medium" sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setOpenRevisions(openRevisions.concat(changedNode.anchor_id))}>
                              expand_more
                            </Icon>
                          )}
                          {changedNode?.x_child_node && openRevisions.indexOf(changedNode.anchor_id) > -1 && (
                            <Icon
                              fontSize="medium"
                              sx={{ float: 'right', cursor: 'pointer' }}
                              onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== changedNode.anchor_id))}
                            >
                              expand_less
                            </Icon>
                          )}
                        </>
                        {openRevisions.indexOf(changedNode.anchor_id) > -1 &&
                          changedNode.x_child_node &&
                          changedNode?.x_child_node
                            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                            ?.map((nodeTwo: DocumentTocProps) => (
                              <>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    my: '10px',
                                  }}
                                >
                                  <MDTypography
                                    variant={'textXS'}
                                    sx={({ palette: { dark } }: Theme) => ({
                                      display: 'inline-flex',
                                      color: nodeTwo.is_article ? '#6060FF' : dark.main,
                                      transition: 'all 0.5s',
                                      mx: nodeTwo.is_leaf ? '5px' : nodeTwo.is_article ? '10px' : null,
                                      '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: '#FEF0C7',
                                      },
                                    })}
                                    onClick={() => {
                                      setAnchor(nodeTwo.anchor_id)
                                      handleEnforceRevisionsTabActive(true)
                                    }}
                                  >
                                    {nodeTwo.nr + ' ' + nodeTwo.title}
                                  </MDTypography>
                                  {!nodeTwo.is_article && nodeTwo?.x_child_node && openRevisions.indexOf(nodeTwo.anchor_id) < 0 && (
                                    <Icon fontSize="medium" sx={{ ml: 'auto', cursor: 'pointer' }} onClick={() => setOpenRevisions(openRevisions.concat(nodeTwo.anchor_id))}>
                                      expand_more
                                    </Icon>
                                  )}
                                  {!nodeTwo.is_article && nodeTwo?.x_child_node && openRevisions.indexOf(nodeTwo.anchor_id) > -1 && (
                                    <Icon
                                      fontSize="medium"
                                      sx={{ ml: 'auto', cursor: 'pointer' }}
                                      onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeTwo.anchor_id))}
                                    >
                                      expand_less
                                    </Icon>
                                  )}
                                </Box>
                                {openRevisions.indexOf(nodeTwo.anchor_id) > -1 &&
                                  nodeTwo.x_child_node &&
                                  nodeTwo?.x_child_node
                                    ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                    ?.map((nodeThree: DocumentTocProps) => (
                                      <>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignContent: 'space-between',
                                            my: '10px',
                                            // my: tocTwo.is_leaf ? '10px' : '16px',
                                            // mx: tocTwo.is_leaf ? '10px' : 0,
                                          }}
                                        >
                                          <MDTypography
                                            variant={'textXS'}
                                            sx={({ palette: { dark } }: Theme) => ({
                                              display: 'inline-flex',
                                              color: nodeThree.is_article ? '#6060FF' : dark.main,
                                              transition: 'all 0.5s',
                                              mx: nodeThree.is_leaf ? '5px' : nodeThree.is_article ? '10px' : null,
                                              '&:hover': {
                                                cursor: 'pointer',
                                                backgroundColor: '#FEF0C7',
                                              },
                                            })}
                                            onClick={() => {
                                              setAnchor(nodeThree.anchor_id)
                                              handleEnforceRevisionsTabActive(true)
                                            }}
                                          >
                                            {nodeThree.nr + ' ' + nodeThree.title}
                                          </MDTypography>
                                          {!nodeThree.is_article && nodeThree?.x_child_node && openRevisions.indexOf(nodeThree.anchor_id) < 0 && (
                                            <Icon
                                              fontSize="medium"
                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                              onClick={() => setOpenRevisions(openRevisions.concat(nodeThree.anchor_id))}
                                            >
                                              expand_more
                                            </Icon>
                                          )}
                                          {!nodeThree.is_article && nodeThree?.x_child_node && openRevisions.indexOf(nodeThree.anchor_id) > -1 && (
                                            <Icon
                                              fontSize="medium"
                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                              onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeThree.anchor_id))}
                                            >
                                              expand_less
                                            </Icon>
                                          )}
                                        </Box>
                                        {openRevisions.indexOf(nodeThree.anchor_id) > -1 &&
                                          nodeThree.x_child_node &&
                                          nodeThree?.x_child_node
                                            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                            ?.map((nodeFour: DocumentTocProps) => (
                                              <>
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    alignContent: 'space-between',
                                                    my: '10px',
                                                    // my: tocTwo.is_leaf ? '10px' : '16px',
                                                    // mx: tocTwo.is_leaf ? '10px' : 0,
                                                  }}
                                                >
                                                  <MDTypography
                                                    variant={'textXS'}
                                                    sx={({ palette: { dark } }: Theme) => ({
                                                      display: 'inline-flex',
                                                      color: nodeFour.is_article ? '#6060FF' : dark.main,
                                                      transition: 'all 0.5s',
                                                      mx: nodeFour.is_leaf ? '5px' : nodeFour.is_article ? '10px' : null,
                                                      '&:hover': {
                                                        cursor: 'pointer',
                                                        backgroundColor: '#FEF0C7',
                                                      },
                                                    })}
                                                    onClick={() => {
                                                      setAnchor(nodeFour.anchor_id)
                                                      handleEnforceRevisionsTabActive(true)
                                                    }}
                                                  >
                                                    {nodeFour.nr + ' ' + nodeFour.title}
                                                  </MDTypography>
                                                  {!nodeFour.is_article && nodeFour?.x_child_node && openRevisions.indexOf(nodeFour.anchor_id) < 0 && (
                                                    <Icon
                                                      fontSize="medium"
                                                      sx={{ ml: 'auto', cursor: 'pointer' }}
                                                      onClick={() => setOpenRevisions(openRevisions.concat(nodeFour.anchor_id))}
                                                    >
                                                      expand_more
                                                    </Icon>
                                                  )}
                                                  {!nodeFour.is_article && nodeFour?.x_child_node && openRevisions.indexOf(nodeFour.anchor_id) > -1 && (
                                                    <Icon
                                                      fontSize="medium"
                                                      sx={{ ml: 'auto', cursor: 'pointer' }}
                                                      onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeFour.anchor_id))}
                                                    >
                                                      expand_less
                                                    </Icon>
                                                  )}
                                                </Box>
                                                {openRevisions.indexOf(nodeFour.anchor_id) > -1 &&
                                                  nodeFour.x_child_node &&
                                                  nodeFour?.x_child_node
                                                    ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                                    ?.map((nodeFive: DocumentTocProps) => (
                                                      <>
                                                        <Box
                                                          sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            alignContent: 'space-between',
                                                            my: '10px',
                                                            // my: tocTwo.is_leaf ? '10px' : '16px',
                                                            // mx: tocTwo.is_leaf ? '10px' : 0,
                                                          }}
                                                        >
                                                          <MDTypography
                                                            variant={'textXS'}
                                                            sx={({ palette: { dark } }: Theme) => ({
                                                              display: 'inline-flex',
                                                              color: nodeFive.is_article ? '#6060FF' : dark.main,
                                                              transition: 'all 0.5s',
                                                              mx: nodeFive.is_leaf ? '5px' : nodeFive.is_article ? '10px' : null,
                                                              '&:hover': {
                                                                cursor: 'pointer',
                                                                backgroundColor: '#FEF0C7',
                                                              },
                                                            })}
                                                            onClick={() => {
                                                              setAnchor(nodeFive.anchor_id)
                                                              handleEnforceRevisionsTabActive(true)
                                                            }}
                                                          >
                                                            {nodeFive.nr + ' ' + nodeFive.title}
                                                          </MDTypography>
                                                          {!nodeFive.is_article && nodeFive?.x_child_node && openRevisions.indexOf(nodeFive.anchor_id) < 0 && (
                                                            <Icon
                                                              fontSize="medium"
                                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                                              onClick={() => setOpenRevisions(openRevisions.concat(nodeFive.anchor_id))}
                                                            >
                                                              expand_more
                                                            </Icon>
                                                          )}
                                                          {!nodeFive.is_article && nodeFive?.x_child_node && openRevisions.indexOf(nodeFive.anchor_id) > -1 && (
                                                            <Icon
                                                              fontSize="medium"
                                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                                              onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeFive.anchor_id))}
                                                            >
                                                              expand_less
                                                            </Icon>
                                                          )}
                                                        </Box>
                                                        {openRevisions.indexOf(nodeFive.anchor_id) > -1 &&
                                                          nodeFive.x_child_node &&
                                                          nodeFive?.x_child_node
                                                            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                                            ?.map((nodeSix: DocumentTocProps) => (
                                                              <>
                                                                <Box
                                                                  sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    alignContent: 'space-between',
                                                                    my: '10px',
                                                                    // my: tocTwo.is_leaf ? '10px' : '16px',
                                                                    // mx: tocTwo.is_leaf ? '10px' : 0,
                                                                  }}
                                                                >
                                                                  <MDTypography
                                                                    variant={'textXS'}
                                                                    sx={({ palette: { dark } }: Theme) => ({
                                                                      display: 'inline-flex',
                                                                      color: nodeSix.is_article ? '#6060FF' : dark.main,
                                                                      transition: 'all 0.5s',
                                                                      mx: nodeSix.is_leaf ? '5px' : nodeSix.is_article ? '10px' : null,
                                                                      '&:hover': {
                                                                        cursor: 'pointer',
                                                                        backgroundColor: '#FEF0C7',
                                                                      },
                                                                    })}
                                                                    onClick={() => {
                                                                      setAnchor(nodeSix.anchor_id)
                                                                      handleEnforceRevisionsTabActive(true)
                                                                    }}
                                                                  >
                                                                    {nodeSix.nr + ' ' + nodeSix.title}
                                                                  </MDTypography>
                                                                  {!nodeSix.is_article && nodeSix?.x_child_node && openRevisions.indexOf(nodeSix.anchor_id) < 0 && (
                                                                    <Icon
                                                                      fontSize="medium"
                                                                      sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                      onClick={() => setOpenRevisions(openRevisions.concat(nodeSix.anchor_id))}
                                                                    >
                                                                      expand_more
                                                                    </Icon>
                                                                  )}
                                                                  {!nodeSix.is_article && nodeSix?.x_child_node && openRevisions.indexOf(nodeSix.anchor_id) > -1 && (
                                                                    <Icon
                                                                      fontSize="medium"
                                                                      sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                      onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeSix.anchor_id))}
                                                                    >
                                                                      expand_less
                                                                    </Icon>
                                                                  )}
                                                                </Box>
                                                                {openRevisions.indexOf(nodeSix.anchor_id) > -1 &&
                                                                  nodeSix.x_child_node &&
                                                                  nodeSix?.x_child_node
                                                                    ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                                                    ?.map((nodeSeven: DocumentTocProps) => (
                                                                      <>
                                                                        <Box
                                                                          sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            alignContent: 'space-between',
                                                                            my: '10px',
                                                                            // my: tocTwo.is_leaf ? '10px' : '16px',
                                                                            // mx: tocTwo.is_leaf ? '10px' : 0,
                                                                          }}
                                                                        >
                                                                          <MDTypography
                                                                            variant={'textXS'}
                                                                            sx={({ palette: { dark } }: Theme) => ({
                                                                              display: 'inline-flex',
                                                                              color: nodeSeven.is_article ? '#6060FF' : dark.main,
                                                                              transition: 'all 0.5s',
                                                                              mx: nodeSeven.is_leaf ? '5px' : nodeSeven.is_article ? '10px' : null,
                                                                              '&:hover': {
                                                                                cursor: 'pointer',
                                                                                backgroundColor: '#FEF0C7',
                                                                              },
                                                                            })}
                                                                            onClick={() => {
                                                                              setAnchor(nodeSeven.anchor_id)
                                                                              handleEnforceRevisionsTabActive(true)
                                                                            }}
                                                                          >
                                                                            {nodeSeven.nr + ' ' + nodeSeven.title}
                                                                          </MDTypography>
                                                                          {!nodeSeven.is_article && nodeSeven?.x_child_node && openRevisions.indexOf(nodeSeven.anchor_id) < 0 && (
                                                                            <Icon
                                                                              fontSize="medium"
                                                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                              onClick={() => setOpenRevisions(openRevisions.concat(nodeSeven.anchor_id))}
                                                                            >
                                                                              expand_more
                                                                            </Icon>
                                                                          )}
                                                                          {!nodeSeven.is_article && nodeSeven?.x_child_node && openRevisions.indexOf(nodeSeven.anchor_id) > -1 && (
                                                                            <Icon
                                                                              fontSize="medium"
                                                                              sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                              onClick={() => setOpenRevisions(openRevisions.filter((a) => a !== nodeSeven.anchor_id))}
                                                                            >
                                                                              expand_less
                                                                            </Icon>
                                                                          )}
                                                                        </Box>
                                                                      </>
                                                                    ))}
                                                              </>
                                                            ))}
                                                      </>
                                                    ))}
                                              </>
                                            ))}
                                      </>
                                    ))}
                              </>
                            ))}
                        <Divider />
                      </Box>
                    ))}
                {!reviewsActive && (
                  <Box>
                    {documentToc
                      ?.filter((tocOne) => !tocOne.is_changed)
                      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                      ?.map((tocOne) => (
                        <Box mb={'8px'}>
                          <>
                            <Box
                              sx={{
                                backgroundColor: tocOne.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                display: 'inline-flex',
                                alignItems: 'center',
                                transition: 'all 0.5s',
                                '&:hover': {
                                  cursor: 'pointer',
                                  backgroundColor: '#FEF0C7',
                                },
                              }}
                            >
                              <div onClick={() => setAnchor(tocOne.anchor_id)}>
                                <MDTypography variant="textSM_SB">{tocOne.nr}:</MDTypography>
                                <MDTypography variant="textSM_M" sx={{ fontWeight: 400, color: '#6c757d', ml: 1 }}>
                                  {tocOne.title && truncateToc(tocOne.title)}
                                </MDTypography>
                              </div>
                            </Box>
                            {!tocOne.is_leaf && tocOne?.x_child_node && openToc.indexOf(tocOne.anchor_id) < 0 && (
                              <Icon fontSize="medium" sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.concat(tocOne.anchor_id))}>
                                expand_more
                              </Icon>
                            )}
                            {!tocOne.is_leaf && tocOne?.x_child_node && openToc.indexOf(tocOne.anchor_id) > -1 && (
                              <Icon fontSize="medium" sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.filter((a) => a !== tocOne.anchor_id))}>
                                expand_less
                              </Icon>
                            )}
                          </>
                          {!tocOne.is_leaf &&
                            tocOne.x_child_node &&
                            openToc.indexOf(tocOne.anchor_id) > -1 &&
                            tocOne?.x_child_node
                              ?.filter((tocTwo) => !tocTwo.is_article && !tocTwo.is_changed)
                              ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                              ?.map((tocTwo: DocumentTocProps, index) => (
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      my: '10px',
                                    }}
                                  >
                                    <MDTypography
                                      variant={'textXS'}
                                      sx={({ palette: { dark } }: Theme) => ({
                                        display: 'inline-flex',
                                        color: dark.main,
                                        textDecoration: tocTwo.anchor_id === 'article_anchor' ? 'underline' : 'none',
                                        mx: tocTwo.is_leaf ? '5px' : null,
                                        transition: 'all 0.5s',
                                        backgroundColor: tocTwo.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                        '&:hover': {
                                          cursor: 'pointer',
                                          backgroundColor: '#FEF0C7',
                                        },
                                      })}
                                      onClick={() => setAnchor(tocTwo.anchor_id)}
                                    >
                                      {tocTwo.nr + ' ' + tocTwo.title}
                                    </MDTypography>
                                    {!tocTwo.is_leaf && tocTwo?.x_child_node && openToc.indexOf(tocTwo.anchor_id) < 0 && (
                                      <Icon fontSize="medium" sx={{ ml: 'auto', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.concat(tocTwo.anchor_id))}>
                                        expand_more
                                      </Icon>
                                    )}
                                    {!tocTwo.is_leaf && tocTwo?.x_child_node && openToc.indexOf(tocTwo.anchor_id) > -1 && (
                                      <Icon fontSize="medium" sx={{ ml: 'auto', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.filter((a) => a !== tocTwo.anchor_id))}>
                                        expand_less
                                      </Icon>
                                    )}
                                  </Box>
                                  {!tocTwo.is_leaf &&
                                    tocTwo.x_child_node &&
                                    openToc.indexOf(tocTwo.anchor_id) > -1 &&
                                    tocTwo?.x_child_node
                                      ?.filter((tocThree) => !tocThree.is_article && !tocThree.is_changed)
                                      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                      ?.map((tocThree: DocumentTocProps) => (
                                        <>
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              alignContent: 'space-between',
                                              my: '10px',
                                            }}
                                          >
                                            <MDTypography
                                              variant={'textXS'}
                                              sx={({ palette: { dark } }: Theme) => ({
                                                color: dark.main,
                                                backgroundColor: tocThree.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                                display: 'block',
                                                textDecoration: tocThree.anchor_id === 'article_anchor' ? 'underline' : 'none',
                                                transition: 'all 0.5s',
                                                mx: tocThree.is_leaf ? '5px' : null,
                                                '&:hover': {
                                                  cursor: 'pointer',
                                                  backgroundColor: '#FEF0C7',
                                                },
                                              })}
                                              onClick={() => setAnchor(tocThree.anchor_id)}
                                            >
                                              {tocThree.nr + ' ' + tocThree.title}
                                            </MDTypography>
                                            {!tocThree.is_leaf && tocThree?.x_child_node && openToc.indexOf(tocThree.anchor_id) < 0 && (
                                              <Icon fontSize="medium" sx={{ ml: 'auto', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.concat(tocThree.anchor_id))}>
                                                expand_more
                                              </Icon>
                                            )}
                                            {!tocThree.is_leaf && tocThree?.x_child_node && openToc.indexOf(tocThree.anchor_id) > -1 && (
                                              <Icon
                                                fontSize="medium"
                                                sx={{ ml: 'auto', cursor: 'pointer' }}
                                                onClick={() => setOpenToc(openToc.filter((a) => a !== tocThree.anchor_id))}
                                              >
                                                expand_less
                                              </Icon>
                                            )}
                                          </Box>
                                          {!tocThree.is_leaf &&
                                            tocThree.x_child_node &&
                                            openToc.indexOf(tocThree.anchor_id) > -1 &&
                                            tocThree?.x_child_node
                                              ?.filter((tocFour) => !tocFour.is_article && !tocFour.is_changed)
                                              ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                              ?.map((tocFour: DocumentTocProps) => (
                                                <>
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      alignContent: 'space-between',
                                                      my: '10px',
                                                    }}
                                                  >
                                                    <MDTypography
                                                      variant={'textXS'}
                                                      sx={({ palette: { dark } }: Theme) => ({
                                                        color: dark.main,
                                                        backgroundColor: tocFour.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                                        display: 'block',
                                                        textDecoration: tocFour.anchor_id === 'article_anchor' ? 'underline' : 'none',
                                                        transition: 'all 0.5s',
                                                        mx: tocFour.is_leaf ? '5px' : null,
                                                        '&:hover': {
                                                          cursor: 'pointer',
                                                          backgroundColor: '#FEF0C7',
                                                        },
                                                      })}
                                                      onClick={() => setAnchor(tocFour.anchor_id)}
                                                    >
                                                      {tocFour.nr + ' ' + tocFour.title}
                                                    </MDTypography>
                                                    {!tocFour.is_leaf && tocFour?.x_child_node && openToc.indexOf(tocFour.anchor_id) < 0 && (
                                                      <Icon fontSize="medium" sx={{ ml: 'auto', cursor: 'pointer' }} onClick={() => setOpenToc(openToc.concat(tocFour.anchor_id))}>
                                                        expand_more
                                                      </Icon>
                                                    )}
                                                    {!tocFour.is_leaf && tocFour?.x_child_node && openToc.indexOf(tocFour.anchor_id) > -1 && (
                                                      <Icon
                                                        fontSize="medium"
                                                        sx={{ ml: 'auto', cursor: 'pointer' }}
                                                        onClick={() => setOpenToc(openToc.filter((a) => a !== tocFour.anchor_id))}
                                                      >
                                                        expand_less
                                                      </Icon>
                                                    )}
                                                  </Box>
                                                  {!tocFour.is_leaf &&
                                                    tocFour.x_child_node &&
                                                    openToc.indexOf(tocFour.anchor_id) > -1 &&
                                                    tocFour?.x_child_node
                                                      ?.filter((tocFive) => !tocFive.is_article && !tocFive.is_changed)
                                                      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                                                      ?.map((tocFive: DocumentTocProps) => (
                                                        <>
                                                          <Box
                                                            sx={{
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              alignContent: 'space-between',
                                                              my: '10px',
                                                            }}
                                                          >
                                                            <MDTypography
                                                              variant={'textXS'}
                                                              sx={({ palette: { dark } }: Theme) => ({
                                                                color: dark.main,
                                                                backgroundColor: tocFive.anchor_id === articleId ? '#FEF0C7' : 'transparent',
                                                                display: 'block',
                                                                mx: tocFive.is_leaf ? '5px' : null,
                                                                textDecoration: tocFive.anchor_id === 'article_anchor' ? 'underline' : 'none',
                                                                transition: 'all 0.5s',
                                                                '&:hover': {
                                                                  cursor: 'pointer',
                                                                  backgroundColor: '#FEF0C7',
                                                                },
                                                              })}
                                                              onClick={() => setAnchor(tocFive.anchor_id)}
                                                            >
                                                              {tocFive.nr + ' ' + tocFive.title}
                                                            </MDTypography>
                                                            {!tocFive.is_leaf && tocFive?.x_child_node && openToc.indexOf(tocFive.anchor_id) < 0 && (
                                                              <Icon
                                                                fontSize="medium"
                                                                sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                onClick={() => setOpenToc(openToc.concat(tocFive.anchor_id))}
                                                              >
                                                                expand_more
                                                              </Icon>
                                                            )}
                                                            {!tocFive.is_leaf && tocFive?.x_child_node && openToc.indexOf(tocFive.anchor_id) > -1 && (
                                                              <Icon
                                                                fontSize="medium"
                                                                sx={{ ml: 'auto', cursor: 'pointer' }}
                                                                onClick={() => setOpenToc(openToc.filter((a) => a !== tocFive.anchor_id))}
                                                              >
                                                                expand_less
                                                              </Icon>
                                                            )}
                                                          </Box>
                                                        </>
                                                      ))}
                                                </>
                                              ))}
                                        </>
                                      ))}
                                </>
                              ))}
                          <Divider />
                        </Box>
                      ))}
                  </Box>
                )}
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default TocPanel
